/* Mobi.css v1.1.0 http://getmobicss.com */
@charset "UTF-8";
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%; }

body {
  background-color: #f3f3f3;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial, Helvetica, STHeiti, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.8rem;
  line-height: 1.5;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 32px 0 0; }

p,
blockquote,
table,
hr,
dl,
ul,
ol,
pre,
address,
figure {
  margin: 14px 0 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600; }

h1 {
  font-size: 3.2rem; }

h2 {
  font-size: 2.6rem; }

h3 {
  font-size: 2.4rem; }

h4 {
  font-size: 2.2rem; }

h5 {
  font-size: 2rem; }

h6 {
  font-size: 1.8rem; }

a {
  color: #0080ff;
  text-decoration: none;
  -webkit-text-decoration-skip: objects; }
  a:active, a:hover {
    text-decoration: underline; }

b,
strong,
dt {
  font-weight: 600; }

code,
kbd,
samp {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 85%;
  padding: 0.1em 0.2em; }

pre {
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.05);
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1.3rem;
  line-height: 1.2;
  margin-left: -14px;
  margin-right: -14px;
  padding: 14px; }
  pre code {
    background-color: transparent;
    font-size: 1.3rem;
    padding: 0; }
  @media (min-width: 740px) {
    pre {
      margin-left: 0;
      margin-right: 0; } }

blockquote {
  border-left: 5px solid #ddd;
  color: #777;
  padding-left: 14px; }

ul,
ol {
  padding-left: 28px; }

dt,
dd,
ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0; }

hr {
  border: 0;
  border-top: 1px solid #ddd; }

small {
  font-size: 85%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

address,
time {
  color: #777;
  font-style: normal; }

mark {
  background-color: #ff0;
  color: #333;
  padding: 0 0.2em; }

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  max-width: 100%;
  vertical-align: middle; }

audio,
video {
  width: 100%; }

[role="button"] {
  cursor: pointer; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  outline: 0;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button,
input,
select,
textarea {
  font: inherit; }

button,
html [type=button],
[type=reset],
[type=submit],
::-webkit-file-upload-button {
  -webkit-appearance: button;
          appearance: button; }

[type=search] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

::-webkit-file-upload-button {
  font: inherit; }

[hidden] {
  display: none; }

fieldset {
  border: 1px solid #ddd;
  margin: 14px 0 0;
  padding: 0 14px 14px; }

legend {
  padding: 0 3px; }

optgroup {
  color: #777;
  font-style: normal;
  font-weight: normal; }

option {
  color: #333; }

progress {
  width: 100%; }

.container {
  background-color: #fff;
  max-width: 740px;
  min-width: 320px;
  outline: 14px solid #fff;
  overflow: hidden;
  padding: 0 14px 14px;
  width: 100%; }

@media (min-width: 1041px) {
  .container {
    max-width: 768px;
    outline: 0;
    padding: 0 28px 14px; } }

.top-gap-big,
.form .top-gap-big {
  margin-top: 32px; }

.top-gap,
.form .top-gap {
  margin-top: 14px; }

.top-gap-0,
.form .top-gap-0 {
  margin-top: 0; }

.flex-left,
.flex-center,
.flex-right,
.flex-top,
.flex-middle,
.flex-bottom,
.flex-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap; }

.flex-left,
.flex-center,
.flex-right,
.flex-top,
.flex-middle,
.flex-bottom,
.flex-vertical.flex-left,
.flex-vertical.flex-center,
.flex-vertical.flex-right,
.flex-vertical.flex-top,
.flex-vertical.flex-middle,
.flex-vertical.flex-bottom {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.flex-center,
.flex-vertical.flex-middle {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.flex-right,
.flex-vertical.flex-bottom {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.flex-top,
.flex-vertical.flex-left {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }

.flex-middle,
.flex-vertical.flex-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.flex-bottom,
.flex-vertical.flex-right {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

.units-gap {
  margin-left: -7px;
  margin-right: -7px; }
  .units-gap > .unit,
  .units-gap > .unit-0,
  .units-gap > .unit-1-2,
  .units-gap > .unit-1-3,
  .units-gap > .unit-2-3,
  .units-gap > .unit-1-4,
  .units-gap > .unit-3-4 {
    padding-left: 7px;
    padding-right: 7px; }

.unit {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.unit-1-2,
.unit-1-3,
.unit-2-3,
.unit-1-4,
.unit-3-4 {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0; }

.unit-1-2 {
  -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
  max-width: 50%; }

.unit-1-3 {
  -webkit-flex-basis: 33.33%;
      -ms-flex-preferred-size: 33.33%;
          flex-basis: 33.33%;
  max-width: 33.33%; }

.unit-2-3 {
  -webkit-flex-basis: 66.67%;
      -ms-flex-preferred-size: 66.67%;
          flex-basis: 66.67%;
  max-width: 66.67%; }

.unit-1-4 {
  -webkit-flex-basis: 25%;
      -ms-flex-preferred-size: 25%;
          flex-basis: 25%;
  max-width: 25%; }

.unit-3-4 {
  -webkit-flex-basis: 75%;
      -ms-flex-preferred-size: 75%;
          flex-basis: 75%;
  max-width: 75%; }

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }
  .flex-vertical > .unit,
  .flex-vertical > .unit-0,
  .flex-vertical > .unit-1-2,
  .flex-vertical > .unit-1-3,
  .flex-vertical > .unit-2-3,
  .flex-vertical > .unit-1-4,
  .flex-vertical > .unit-3-4 {
    max-width: none; }
  .flex-vertical > .unit-1-2 {
    max-height: 50%; }
  .flex-vertical > .unit-1-3 {
    max-height: 33.33%; }
  .flex-vertical > .unit-2-3 {
    max-height: 66.67%; }
  .flex-vertical > .unit-1-4 {
    max-height: 25%; }
  .flex-vertical > .unit-3-4 {
    max-height: 75%; }

.table {
  background-color: #fff;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }
  .table caption {
    caption-side: bottom;
    color: #777;
    padding: 5px;
    text-align: left; }
  .table th,
  .table td {
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    text-align: left; }
  .table th {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: 600; }

.form {
  margin: 0; }
  .form label {
    cursor: pointer;
    display: block;
    line-height: 1.25;
    margin-top: 14px;
    padding-bottom: 6px;
    padding-top: 6px; }
  .form [type=text],
  .form [type=password],
  .form [type=email],
  .form [type=search],
  .form [type=url],
  .form [type=tel],
  .form [type=number],
  .form textarea,
  .form select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial, Helvetica, STHeiti, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.8rem;
    padding: 5px 10px;
    display: block;
    line-height: 1.25;
    margin: 14px 0 0;
    width: 100%; }
    .form [type=text]:focus,
    .form [type=password]:focus,
    .form [type=email]:focus,
    .form [type=search]:focus,
    .form [type=url]:focus,
    .form [type=tel]:focus,
    .form [type=number]:focus,
    .form textarea:focus,
    .form select:focus {
      border-color: #3399ff; }
  @media (max-width: 1040px) {
    .form [type=time],
    .form [type=week],
    .form [type=month],
    .form [type=date],
    .form [type=datetime-local] {
      margin: 14px 0 0; } }
  @media (min-width: 1041px) {
    .form [type=time],
    .form [type=week],
    .form [type=month],
    .form [type=date],
    .form [type=datetime-local] {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 3px;
      color: #333;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial, Helvetica, STHeiti, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 1.8rem;
      padding: 5px 10px;
      display: block;
      line-height: 1.25;
      margin: 14px 0 0;
      width: 100%; }
      .form [type=time]:focus,
      .form [type=week]:focus,
      .form [type=month]:focus,
      .form [type=date]:focus,
      .form [type=datetime-local]:focus {
        border-color: #3399ff; } }
  .form [type=checkbox],
  .form [type=radio] {
    cursor: pointer;
    margin: 0 5px 0 0; }
  .form select {
    background: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='20' viewBox='0 0 32 20'><path fill='#333' d='M0 0h32L16 20z'/></svg>") right 8px center no-repeat;
    background-size: 8px 8px;
    padding-right: 25px;
    cursor: pointer; }
  .form [type=file],
  .form [type=range] {
    display: block;
    line-height: 1.25;
    margin: 14px 0 0;
    width: 100%;
    cursor: pointer;
    padding: 6px 0; }
  .form [type=color],
  .form [type=image] {
    cursor: pointer;
    display: block;
    margin: 14px 0 0; }
  .form [disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none; }
  .form [readonly] {
    background-color: #f3f3f3; }

.btn {
  display: block;
  line-height: 1.25;
  margin: 14px 0 0;
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial, Helvetica, STHeiti, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.8rem;
  padding: 5px 10px;
  background-color: #f3f3f3;
  cursor: pointer;
  text-align: center; }
  .btn:focus {
    border-color: #3399ff; }
  .btn:hover, .btn:active {
    text-decoration: none; }
  .btn[disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none; }

.btn-primary {
  background-color: #40bf40;
  border-color: #40bf40;
  color: #fff; }
  .btn-primary[disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none; }

.btn-danger {
  color: #cc0000; }
  .btn-danger[disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none; }

@media (max-width: 1040px) {
  .hide-on-mobile {
    display: none; } }

@media (min-width: 1041px) {
  .show-on-mobile {
    display: none; } }

.scroll-view {
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-muted {
  color: #777; }

a.text-muted {
  color: #777;
  text-decoration: underline; }

/*# sourceMappingURL=mobi.css.map */
